import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { I18nContext, useDefaultLanguage } from "../I18n"

interface AllWordpressPage {
  allWpMenuItem: {
    nodes: {
      id: string
      path: string
      label: string
      locations: string[]
    }[]
  }
}

export default () => {
  const {
    allWpMenuItem: {
      nodes
    }
  }: AllWordpressPage = useStaticQuery(graphql`
  query {
    allWpMenuItem(
      filter: {locations: {in: [CONTACT, CONTACT___EN]}}
      sort: {order: ASC, fields: order}
    ) {
      nodes {
        id
        path
        label
        locations
      }
    }
  }`)

  const { locale } = useContext(I18nContext)
  const { locale: defaultLocale } = useDefaultLanguage()

  return (
    <>
      {nodes
      .filter((i) => {
        return defaultLocale === locale && i.locations.includes(`CONTACT`)
        || i.locations.includes(`CONTACT___${locale?.toUpperCase()}`)
      })
      .map(({
        id, label, path
      }) => (
        <Link
          id={id}
          to={path}
          key={id}
        >{label}</Link>
      )
    )}
    </>
  )
}
