import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql } from "gatsby"
import PageList from "~/components/PageList"
import useScrollToTop from "~/hooks/useScrollToTop"
import useMaxWidth from "~/hooks/useMaxWidth"
import Background from "~/components/BackgroundImage"

// @ts-ignore
import PhoneIcon from "~/images/Icons/icon-phone.svg"
// @ts-ignore
import MessageIcon from "~/images/Icons/icon-email.svg"
// @ts-ignore
import MarkerIcon from "~/images/Icons/icon-address.svg"
import { I18n, I18nContext } from "~/components/I18n"

export const query = graphql`{
  site {
    siteMetadata {
      title
      description
      author
      image
      siteUrl
    }
  }
  wpMenuItem(url: {regex: "/^\\/contact/"}) {
    id
    label
  }
  bgimage: file(relativePath: {eq: "Pictures/get-in-touch.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 72, layout: FULL_WIDTH)
    }
  }
}
`

export default ({
  data: {
    bgimage,
    wpMenuItem: menuItem,
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  bgimage: any
  wpMenuItem?: {
    id: string
    label: string
  },
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
}>) => {
  useScrollToTop()
  useMaxWidth({
    isWide: true
  })

  const { locale } = useContext(I18nContext)

  return <>
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: locale,
        dir: "ltr",
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      <title>{title}</title>
      <link rel="author" href="/humans.txt" />

      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}${image}`} />

      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${siteUrl}${image}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />


    </Helmet>

    <Background
      image={bgimage.childImageSharp.gatsbyImageData}
      position="bottom"
    >

      <header className="contacts-header is-uppercase">
        <h1>{menuItem?.label || "Get In Touch"}</h1>
      </header>

      <div className="contacts-grid">

        <section className="is-fluid grid-area-main">
          <a className="has-icon-left" href="tel:+351211587250">
            <PhoneIcon height="32" width="32" /> +351 211 587 250
          </a>

          <a className="has-icon-left" href="mailto:agency@brandfire.pt">
            <MessageIcon height="32" width="32" /> agency@brandfire.pt
          </a>

          <a className="has-icon-left" href="https://goo.gl/maps/j7GwhkXYxL7eYjt7A" target="_blank" rel="noreferrer nofollow noopener external">
            <MarkerIcon height="32" width="32" />Rua Rodrigues Sampaio, 31 - 1 Direito<br />1150-139 <I18n pt="Lisboa" en="Lisbon" />
          </a>
        </section>

        <section className="is-fluid grid-area-maps">
          <div className="is-responsive-16-9">
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1RyV9KMZ4ALCaSh7wyE3Ak2VsAj--f6rt&ehbc=2E312F"
              aria-hidden="false"
            />
          </div>
        </section>

        <footer className="copyright is-fluid grid-area-footer">
          <PageList />
          <br />
          {new Date().getFullYear()} © Brandfire // All rights reserved.
        </footer>
      </div>

    </Background>
  </>;
}
