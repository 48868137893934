import React, { FC, PropsWithChildren, ReactElement, ReactNode } from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import cc from "classcat"
import "./index.css"

const BackgroundImage: FC<PropsWithChildren<{
  image: IGatsbyImageData
  position?: "bottom" | "center" | "screen"
  className?: string
}>> = ({
  image,
  position = "center",
  children,
  className,
}) => {

  return (
    <div className={cc(["background-image__container", className])}>
      <div className={cc([
        "background-image__background",

        position === "screen" && "w-screen",
        position === "center" && "background-image__background-position-center",
        position === "bottom" && "background-image__background-position-bottom",
      ])}>
        <GatsbyImage image={image} alt="" />
      </div>
      <div className="background-image__foreground">
        {children}
      </div>
    </div>
  );
}

export default BackgroundImage
